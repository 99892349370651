import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/widget',
      name: 'Widget',
      component: () => import('@/views/widgets/funnel_widget/FunnelWidget'),
    },
    {
      path: '/anamnesis',
      name: 'Anamnesis Widget',
      component: () => import('@/views/widgets/anamnesis_widget/AnamnesisWidget'),
    },
    {
      path: '/headsup',
      name: 'Headsup Component',
      component: () => import('@/views/headsups/Headsup'),
    },
    {
      name: 'Theme404 Error',
      path: '*',
      component: () => import('@/views/landing/NotFound'),
    },
 ],
})

export default router
