import axios from 'axios'

export default axios.create({
  transformRequest: [...axios.default.defaults.transformRequest, function (data, headers) {
    headers['Content-Type'] = 'application/vnd.api+json'
    return data
  }],
  transformResponse: [...axios.default.defaults.transformResponse, function (data) {
    return data
  }],
})
