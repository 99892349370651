import Vue from 'vue'
import vuetify from './plugins/vuetify'
import './plugins/base'
import './plugins/vee-validate'
import './plugins/youtube'
import './plugins/vimeo'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import airbrake from './plugins/airbrake'

Vue.config.productionTip = false

Vue.config.errorHandler = function (err, vm, info) {
  airbrake.notify({
    error: err,
    params: { info: info },
  })
  throw err
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
