import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({})

export const loadStoreModule = ({ moduleName = 'currentPage', module }) => {
  unloadStoreModule(moduleName)
  if (!module) {
    const error = '[loadStoreModule] Module is not defined.'
    return console.error(error)
  }
  store.registerModule(moduleName, module)
}

export const unloadStoreModule = (moduleName = 'currentPage') => {
  /** Note: Implement the 'clear' mutation on individual stores.
  */
  if (!store.state[moduleName]) { return }
  const clearActionName = moduleName + '/clear'
  if (store._mutations[clearActionName]) {
    store.commit(clearActionName)
  }
  store.unregisterModule(moduleName)
}

store.loadStoreModule = loadStoreModule
store.unloadStoreModule = unloadStoreModule

export default store
