import Vue from 'vue'
import Vuetify, { VRow } from 'vuetify/lib'
import i18n from '@/i18n'

Vue.use(Vuetify, {
  components: { VRow },
})

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      light: {
        primary: '#9E9E9E',
        secondary: '#0F22A7',
        accent: '0066FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        anchor: '#00000A',
        cta: '#FEBA4F',
      },
    },
  },
})
