import axiosApi from '@/helpers/axios'

var validationApiServices = {
  async getClinicLocales ({ clinicWidgetId }) {
    const baseUrl = process.env.VUE_APP_API_ASSESSMENTS_URL
    const endpoint = `public-assessments/${clinicWidgetId}/get_clinic_widget_locales_by_widget_id/`
    return axiosApi.get(`${baseUrl}/${endpoint}`)
  },
}

export default validationApiServices
