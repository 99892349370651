import Vue from 'vue'
import VueI18n from 'vue-i18n'

import axiosApi from '@/helpers/axios'
import validationApiServices from '@/views/widgets/services/validation_endpoints'

import defaultEn from '@/locales/defaults/default_en.json'
import widgetEn from '@/locales/defaults/widget_en.json'
import anamnesisEn from '@/locales/defaults/anamnesis_en.json'
import emergencyEn from '@/locales/defaults/emergency_en.json'

import defaultDa from '@/locales/defaults/default_da.json'
import widgetDa from '@/locales/defaults/widget_da.json'
import anamnesisDa from '@/locales/defaults/anamnesis_da.json'
import emergencyDa from '@/locales/defaults/emergency_da.json'

import {
  localize,
} from 'vee-validate'

Vue.use(VueI18n)

const loadedLanguages = []

const defaultLanguage = 'da'

const messages = {
  en: {
    ...defaultEn,
    ...widgetEn,
    ...anamnesisEn,
    ...emergencyEn,
  },
  da: {
    ...defaultDa,
    ...widgetDa,
    ...anamnesisDa,
    ...emergencyDa,
  },
}

const i18n = new VueI18n({
  locale: defaultLanguage, // NOTE: Intended as a 'clinic', not a language
  fallbackLocale: defaultLanguage,
  messages,
  silentTranslationWarn: true, // Turn 'false' for any debug
})
export default i18n

function setI18nLanguage ({ clinicWidgetId, fallbackLanguage }) {
  i18n.locale = clinicWidgetId
  i18n.fallbackLocale = fallbackLanguage || defaultLanguage
  axiosApi.defaults.headers.common['Accept-Language'] = clinicWidgetId
  document.querySelector('html').setAttribute('lang', clinicWidgetId)
  return clinicWidgetId
}

export async function loadLanguageAsync ({ clinicWidgetId, messages = null, fallbackLanguage = null }) {
  /** Loads locales from API and injects runtime.
   */
  if (loadedLanguages.includes(clinicWidgetId)) {
    if (i18n.locale !== clinicWidgetId) {
      setI18nLanguage(clinicWidgetId)
    }
    return Promise.resolve()
  }
  if (!messages || !fallbackLanguage) {
    const response = await validationApiServices.getClinicLocales({ clinicWidgetId })
    messages = response.data.data.attributes.clinic_widget_locales_as_json
    fallbackLanguage = response.data.data.attributes.fallback_locales_language
  }
  loadedLanguages.push(clinicWidgetId)
  i18n.setLocaleMessage(clinicWidgetId, messages)
  setI18nLanguage({ clinicWidgetId, fallbackLanguage })
}

// export async function setI18nMessageKey ({ locale, key, value }) {
//   let messages = JSON.parse(JSON.stringify(i18n.messages[locale]))
//   messages[key] = value
//   i18n.setLocaleMessage(locale, messages)
// }

localize({
  en: {
    messages: {
      required: i18n.t('REQUIRED_FIELD'),
    },
  },
  da: {
    messages: {
      required: i18n.t('REQUIRED_FIELD'),
    },
  },
})
