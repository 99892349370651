<template>
  <!-- NOTE: By default the application has no background as it is intended
      injected in iframes (current implementation). When a background color
      incl. white is needed, this needs to be implemented on component/page
      injected. By doing it like this way around, it avoids a small 'glimpse'
      of white background on load. Example:

      created () {
        const appDiv = document.getElementById('app')
        if (appDiv) { appDiv.setAttribute('style', 'background: white;') }
      },

      TODO: In due time, figure out if this can be done more elegantly.
  -->
  <v-app style="background: none;">
    <main v-bind="attrs">
      <router-view />
    </main>
  </v-app>
</template>

<script>
  export default {
    computed: {
      attrs () {
        return {
          key: this.$route.path,
          style: {
            minHeight: 'calc(100vh - ' + this.$vuetify.application.top + 'px',
          },
        }
      },
    },
  }
</script>

<style lang="sass">
  section
    padding-bottom: 64px

    @media screen and (max-width: 640px)
      padding-bottom: 16px
</style>
